
import { Component, Mixins } from 'vue-property-decorator';
import { Profile, ListingStatus, WebsiteLevel, Context } from 'client-website-ts-library/types';
import { API, Config } from 'client-website-ts-library/services';
import { AsyncView, RouteMeta } from 'client-website-ts-library/plugins';

import Masthead from '@/components/Common/Masthead.vue';
import { AnalyticsEventObject } from 'client-website-ts-library/services/Analytics';
import Form from '@/components/Forms/Form.vue';
import { ContextItemType } from 'client-website-ts-library/types/Context';

@Component({
  components: {
    Masthead,
    Form,
  },
})
export default class AgentProfile extends Mixins(AsyncView) {
  private profile: Profile | null = null;

  private formData: Context | null = null;

  resolveId() {
    const { id } = this.$route.params;

    if (id.length === 36) {
      // If the ID is 36 long we'll treat it as a guid

      this.updateAgent(id);
    } else {
      API.Slugs.Resolve(id).then((resolved) => {
        if (resolved.Type === 'Profile') {
          this.updateAgent(resolved.Id);
        }
      });
    }
  }

  stripHtml(html: string): string {
    const el = document.createElement('div');

    el.innerHTML = html;

    return el.innerText;
  }

  head(): RouteMeta | null {
    if (this.profile === null) return null;

    let about = this.stripHtml(this.profile.AboutAgent);

    if (about.length > 156) {
      about = `${about.substring(0, 156)}...`;
    }

    return {
      title: `Get an Appraisal - ${this.profile.FullName} - ${this.profile.Position}`,
      description: about,
      image: this.profile.Photo ? this.profile.Photo.Preview.Url : undefined,
    };
  }

  updateAgent(id: string) {
    this.formData = null;

    API.Profiles.Get(id).then((profile) => {
      this.profile = profile;

      this.$emit('updateView');

      this.formData = {
        Items: [{
          Type: ContextItemType.EnquiryTargetProfile,
          Id: profile.Id,
        }],
        ClientWebsiteId: Config.Website.Id,
        WebsiteId: Config.Website.Settings!.WebsiteId,
        WebsiteLevel: Config.Website.Settings!.WebsiteLevel,
      };
    });
  }

  mounted() {
    this.resolveId();
  }

  getAnalyticsData(): AnalyticsEventObject | null {
    if (this.profile === null) return null;

    return AnalyticsEventObject.FromProfile(this.profile);
  }
}
